
import { defineComponent, PropType } from "vue";
import {
  ButtonComponent,
  TInputComponent,
  TSelectComponent,
  ModalComponent,
  isNullOrUndefined,
  NotificationsService,
  TABLE_ICONS,
} from "table";
import { ReportDto } from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import { SelectedReportForm } from "@/modules/registration-reports/components/CreateReportFormModal/CreateReportFormModal.vue";

function getInitFormData() {
  return {
    reportingForms: [{}] as Array<SelectedReportForm>,
  };
}

export default defineComponent({
  name: "AddReportFormModal",
  emits: [
    "on-add-report-forms", // Эмит выбранных форм при нажатии кнопки Добавить ({report, forms: {selectValue, from, to}})
  ],
  components: {
    TInputComponent,
    TSelectComponent,
    ModalComponent,
    ButtonComponent,
  },
  props: {
    report: {
      type: Object as PropType<ReportDto>,
      required: true,
    },
    reportingFormsExcluded: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup() {
    return {
      TABLE_ICONS,
    };
  },
  data() {
    return {
      formData: getInitFormData(),
    };
  },
  computed: {
    reportingFormsExcludedComputed() {
      return [
        ...this.reportingFormsExcluded,
        ...this.formData.reportingForms
          .map((x) => x.selectValue?.value.toString())
          .filter((x) => !isNullOrUndefined(x)),
      ];
    },
  },
  methods: {
    onAddReportForms() {
      const reportingForms = this.formData.reportingForms.filter(
        (x) => x.selectValue,
      );
      if (reportingForms.length === 0) {
        NotificationsService.send({
          type: "error",
          title: "Ошибка при валидации формы",
          text: "Заполните хотя бы одну отчётную форму",
          duration: 5000,
        });
        return;
      }

      this.$emit("on-add-report-forms", {
        report: this.report,
        forms: reportingForms,
      });
    },
  },
});
